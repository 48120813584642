<div class="card">
    <div class="card-header">
        <h4>Danh sách đơn đặt hàng từ sàn OCOP</h4>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label for="">Tìm theo mã đơn hàng</label>
                    <input type="text" class="form-control">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label for="">Trạng thái</label>
                    <select class="form-control">
                        <option>Chưa xác nhận</option>
                        <option>Đã xác nhận</option>
                        <option>Đang xử lý đơn hàng</option>
                        <option>Đang đợi vận chuyển</option>
                        <option>Đang vận chuyển</option>
                        <option>Hoàn thành và thanh toán</option>
                    </select>
                </div>
            </div>
            <div class="col-12 mt-3 mb-3">
                <button class="btn btn-primary" (click)="findOrders()">Tìm đơn hàng</button>
            </div>
        </div>

        <div class="row">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Người mua</th>
                        <th scope="col">Tình trạng</th>
                        <th scope="col">Sản phẩm</th>
                        <th scope="col">Tổng tiền</th>
                        <th scope="col">Thông tin vận chuyển</th>
                       
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of orders">
                        <td>
                            #{{order.code}}
                        </td>
                        <td>
                            Ngày đặt: <strong>{{order.create_on | dateNumberFormat}}</strong><br/>
                            Tên người mua: <strong>{{order.buyer}}</strong><br/>
                            Địa chỉ: <strong>{{order.address}}</strong><br/>
                            Điện thoại: <strong>{{order.phone}}</strong>
                        </td>
                        <td>
                            <span class="badge bg-success">Hoàn thành</span>
                        </td>
                        <td>
                            <ul class="product_info_list">
                                <li *ngFor="let item of order.details">{{item.product_name}} ~ SL: {{item.quantities}} {{item.unit_name}}</li>
                            </ul>
                        </td>
                        <td>
                            Giá tiền: <strong> {{order.total_money | number}}</strong> VND <br/>
                            Phí vận chuyển: <strong> {{order.transport_fee | number}}</strong> VND <br/>
                            Tổng tiền: <strong> {{order.total_money + order.transport_fee | number}}</strong> VND
                        </td>
                        <th scope="col">
                            Đơn vị vận chuyển: VNPT <br/>
                            Phí giao hàng: {{order.transport_fee | number}} VND<br/>
                            <a href="/">Xem chi tiết</a>
                        </th>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</div>