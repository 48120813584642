import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-magnific-popup',
  templateUrl: './magnific-popup.component.html',
  styleUrls: ['./magnific-popup.component.css']
})
export class MagnificPopupComponent implements AfterViewInit {

  @ViewChild('image', { static: false }) image: ElementRef;

  @Input('url') url: any;

  @Input('customcss') customCss: any;

  @Output('closeCallback')
  closeCallback: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngAfterViewInit() {
    $(this.image.nativeElement).magnificPopup({
      type: 'image',
      callbacks: {
        close: () => {
          this.closeCallback.emit(1);
        }
      }
    });
  }

}
