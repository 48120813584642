import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CirclLayoutComponent } from './layouts/circl-layout/circl-layout.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/user/role',
    pathMatch: 'full',
  },
  {
    path: '', component: CirclLayoutComponent, children: [
      { path: '', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule) }
    ]
  },
  {
    path: 'customer', component: CirclLayoutComponent, children: [
      { path: '', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) }
    ]
  },
  {
    path: 'product', component: CirclLayoutComponent, children: [
      { path: '', loadChildren: () => import('./products/products.modules').then(m => m.ProductsModule) }
    ]
  },
  {
    path: 'user', children: [
      { path: '', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) }
    ]
  },
  {
    path: 'production', component: CirclLayoutComponent, children: [
      { path: '', loadChildren: () => import('./production/production.module').then(m => m.ProductionModule) }
    ]
  },
  { path: 'accessories',component: CirclLayoutComponent, loadChildren: () => import('./accessories/accessories.module').then(m => m.AccessoriesModule) },
  { path: 'diary',component: CirclLayoutComponent, loadChildren: () => import('./diaries/diaries.module').then(m => m.DiariesModule) },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
