import {Component, OnInit, AfterViewInit, Input, Output, ViewChild, ElementRef, EventEmitter} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.css']
})
export class Select2Component implements AfterViewInit {

  @ViewChild('selectelement', {static: false}) selectElement: ElementRef;

  @Input('data')
  dateValue: any[];

  @Input('inmodal')
  showInModal = "";

  @Input('selected')
  selectVal = 0;

  @Output('submitChange')
  submitChange: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit() {
    $(document).ready(() => {
      $(this.selectElement.nativeElement).select2();

      $(this.selectElement.nativeElement).on('select2:select', (e) => {
        var data = e.params.data;
        //console.log(data.id);
        this.submitChange.emit(data.id);
      });

      if (this.showInModal !== "") {
        $(this.selectElement.nativeElement).select2({
          dropdownParent: $('#' + this.showInModal)
        });
      }

      $(this.selectElement.nativeElement).val(this.selectVal); // Select the option with a value of '1'
      $(this.selectElement.nativeElement).trigger('change'); // Notify any JS components that the value changed

    });
  }


}
