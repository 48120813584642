import { Component, OnInit, AfterViewInit, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { DGKLanguageService } from '../../services/language.service';
import { TextSelect } from '../../models/data.models';
declare var $: any;
@Component({
  selector: 'app-select-picker',
  templateUrl: './select-picker.component.html',
  styleUrls: ['./select-picker.component.css']
})
export class SelectPickerComponent implements AfterViewInit {


  @Input('arrayData')
  arrayData: TextSelect[] = [];

  valueChoose = 0;

  languagePrefix = '';

  @Input('title')
  title = '';


  @Output('submitChange')
  submitChange: EventEmitter<any> = new EventEmitter();

  constructor(private languageService: DGKLanguageService) {
    if (this.languageService.language_id == 1) {
      this.languagePrefix = '';
    } else {
      this.languagePrefix = '_en';
    }

    this.languageService.languageChange.subscribe((p: string) => {
      if (p === 'vi') {
        this.languagePrefix = '';
      } else {
        this.languagePrefix = '_en';
      }
    });
  }

  ngAfterViewInit() {
  
    if ($(".selectpicker").length != 0) {
      $(".selectpicker").selectpicker();
    }

  }

  changeValue(event) {
    this.submitChange.emit(this.valueChoose);
  }

}
