import { Component, OnInit } from '@angular/core';
import { ResultInfo } from '../../models/data.models';
import { ProductService } from '../../services/product.service';
import { callServiceHttp } from '../../settings/utils';

@Component({
  selector: 'app-product-orders',
  templateUrl: './product-orders.component.html',
  styleUrls: ['./product-orders.component.css']
})
export class ProductOrdersComponent implements OnInit {
  orders = [];
  constructor(private productService: ProductService) { }

  ngOnInit(): void {
      this.findOrders();
  }

  findOrders() {
    callServiceHttp(this.productService.findOrders('code'), (p: ResultInfo) => {
      this.orders = p.reply;
    });
  }

}
