import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-my-modal',
  templateUrl: './my-modal.component.html',
  styleUrls: ['./my-modal.component.css']
})
export class MyModalComponent implements OnInit {


  @Input('modalClass')
  modalClass = 'modal-dialog';

  @Output('submitAccept')
  actionSubmit = new EventEmitter<any>();

  @Input('modalId')
  modalId = 'mymodal';

  @Input('title')
  title = '';

  @Input('showFooter')
  isShowFooter = false;


  @Input('showCloseFooter')
  isShowCloseFooter = true;



  @Input('showAcceptFooter')
  isShowAcceptFooter = true;


  @Input('btnAcceptText')
  btnAcceptText = 'Đồng ý';

  constructor() { }

  ngOnInit(): void {
  }


  submitAccept() {
    this.actionSubmit.emit(1);
  }

}
