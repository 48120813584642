import * as CryptoJS from 'crypto-js';

const sceretKey = '2123nmdsff1231k@!#!@#DASDA@!#!@';

export function enCryptAES(data) {
    //console.log(data);
    var encrypted = CryptoJS.AES.encrypt(data, sceretKey);
    return encrypted;
}

export function deCryptAES(dataEnCrypted) {


    //console.log(dataEnCrypted);
    var decrypted = CryptoJS.AES.decrypt(dataEnCrypted, sceretKey).toString(CryptoJS.enc.Utf8);;

    return decrypted;
}



export function encodeBase64(str: string) {

    try {
        if (typeof str == 'object') {
            throw 'error';
        }
        return window.btoa(unescape(encodeURIComponent(str)));
    } catch (e) {
        return '';
    }

}

//Decode
export function decodeBase64(str: string) {

    try {
        if (typeof str != 'string') {
            throw 'error';
        }
        return decodeURIComponent(escape(window.atob(str)));
    } catch (e) {
        return '';
    }

}

