// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAcQkpn2UFXxm5_CsEkC77pMuAvmgYliNU",
    authDomain: "vn-traceability.firebaseapp.com",
    databaseURL: "https://vn-traceability.firebaseio.com",
    projectId: "vn-traceability",
    storageBucket: "vn-traceability.appspot.com",
    messagingSenderId: "767765441457",
    appId: "1:767765441457:web:8240683301f819e96eab90",
    measurementId: "G-2LQSBDYFEC"
  }
};*/

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCM_JfhG6l8W-5-tpdgWuOMA1q3oLIN9xg",
    authDomain: "dgkhub-805af.firebaseapp.com",
    databaseURL: "https://dgkhub-805af-default-rtdb.firebaseio.com",
    projectId: "dgkhub-805af",
    storageBucket: "dgkhub-805af.appspot.com",
    messagingSenderId: "403424733495",
    appId: "1:403424733495:web:2d945378297eed15b6d2b2",
    measurementId: "G-ZCWX8VL8ED"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
