export var ACCOUNT_URLS = {
    'signin' : 'account/signin',
    'signup' : 'account/signup',
    'check_guard': 'account/guard',
    'active_demo': 'account/activedemo',
    'account_info': 'account/infos',
    'update_profile_info': 'account/updateprofileinfos',
    'change_password': 'account/changepassword',
    'change_avatar': 'account/changeavatar',
    'send_require_reset_password': 'account/requireresetpassword',
    'reset_password':'account/resetpassword'
};

export var CUSTOMER_URLS = {
    'infos': 'company/inf',
    'update_infos': 'company/info/update',
    'getcustomer_type': 'customer/getcustomertype',
    'certificate_upload_image': 'customer/certificate/uploadimages',
    'certificate_images': 'customer/certificate/getimages',
    'certificate_remove_image':'customer/certificate/removeimage',
    'representative_update': 'customer/representative/update',
    'representative_upload_image': 'customer/representative/uploadimage',
    'staffs': 'customer/staff/list',
    'staff_create': 'customer/staff/create',
    'staff_edit': 'customer/staff/edit',
    'staff_list_active':'customer/staff/listactivate',
    'staff_set_password':'customer/staff/setpassword',
    'customer_common_data': 'data/getcustomercommondata'
};

export var COMMON_DATA_URLS = {
    'get_processing_type': 'data/getprocessingtype',
    'get_provinces':'data/getprovince',
    'get_province_withcountryid':'data/getprovincewithcountryid',
    'get_districts' : 'data/getdistrict',
    'get_countries':'data/getcountries',
    'get_currency_types': 'data/getcurrencytypes',
    'get_language_support': 'data/getlanguagesupport',
    'get_payment_method': 'data/getPaymentMethod',
    'data_getlistunit':'data/getlistunit',
    'data_getlistunitcal':'data/getlistunitcal/'
};

export var DGKORDER_URLS = {
    'get_orders' : 'orders/getordes',
    'get_order_info': 'orders/getorderinfo',
    'check_use_orderitem':'orders/checkuseorderitem',
    'active_order_item':'orders/activeorderitem'
};

export var EVENT_URLS ={
    'events_by_year' :'works/event/list?year=',
    'board_create':'works/event/create',
    'board_info': 'works/event/info',
    'board_workitem_create': 'works/workitem/create',
    'board_workitem_list': 'works/workitem/list',
    'board_task_add': 'works/task/add',
    'board_worktask_integrate_gettype': 'works/worktask/integrate/gettype',
    'board_worktask_integrate_getdata':'works/worktask/integrate/getdata',
    'board_worktask_integrate_addrepository': 'works/worktask/integrate/addrepository',
    'board_worktask_integrate_removerepository': 'works/worktask/integrate/removerepository',
    'board_worktask_integrate_get': 'works/worktask/integrate/get',
    'board_worktask_integrate_addpartner': 'works/worktask/integrate/addpartner',
    'board_worktask_integrate_removerepartner': 'works/worktask/integrate/removerepartner',
    'board_worktask_image_add':'works/worktask/image/add',
    'boards_worktask_image_get':'works/worktask/image/get',
    'board_worktask_image_remove':'works/worktask/image/remove',
    'board_worktask_activity_add':'works/worktask/activity/add',
    'board_worktask_activity_all':'works/worktask/activity/all',
    'board_worktask_modify':'works/worktask/modify'
};


export var FARM_URLS = {
    'farm_findall': 'farm/findall',
    'farm_findall_productgroup':'farm/findall/productgroup',
    'farm_getinfos':'farm/getinfos?farm_id=',
    'farm_addnew':'farm/addnew',
    'farm_update':'farm/updatefarm',
    'farm_uploadimage':'farm/uploadimage',
    'farm_removeimage':'farm/removeimage',
    'farm_changestaff':'farm/changestaff'
};


export var PRODUCT_URLS = {
    'product_productgroup_newgroup':'product/productgroup/newgroup',
    'product_productgroup_productgroupedit':'product/productgroup/productgroupedit',
    'product_productgroup_addfarm':'product/productgroup/addfarm',
    'product_productgroup_getlistproductgroups':'product/productgroup/getlistproductgroups',
    'product_productgroup_getlistforsearch':'product/productgroup/getlistforsearch',
    'product_productgroup_productgroupinfos':'product/productgroup/productgroupinfos',
    'product_createproduct':'product/createproduct',
    'product_getallproductbygroup':'product/getallproductbygroup',
    'product_getproductinfos':'product/getproductinfos',
    'product_updateproduct':'product/updateproduct',
    'product_traceability':'product/traceability',
    'product_uploadproductimage':'product/uploadproductimage',
    'product_uploadthumbnail':'product/updatethumbnail',
    'product_removeproductimage':'product/removeproductimage',
    'product_updateproductdescription':'product/updateproductdescription',
    'product_exportexcelbarcode':'product/exportexcelbarcode/',
    'product_productgroup_setproductgroupstandar':'product/productgroup/setproductgroupstandar',
    'data_getinitproduct':'data/getinitproduct',
    'product_makecopy':'product/makecopy',
    'product_repository_selfprint_getavailable':'product/repository/selfprint/getavailable',
    'product_review':'product/review',
    'product_species_add':'product/species/add',
    'product_species_getall':'product/species/getall',
    'product_promotion_set':'product/promotion/set',
    'product_barcode_batch_findinfo':'product/productbatch/batch/findinfo'
};

export var REPOSITORY_URLS = {
    'product_repository_stamp_getordernotimport':'product/repository/stamp/getordernotimport',
    'product_repository_stamp_getorderbatch':'product/repository/stamp/getorderbatch',
    'product_repository_stamp_importbatch':'product/repository/stamp/importbatch',
    'product_repository_stamp_importallbatch':'product/repository/stamp/importallbatch',
    'product_repository_stamp_reportstampprinted':'product/repository/stamp/reportstampprinted',
    'product_repository_stampprinted_getallproduce':'product/repository/stampprinted/getallproduce',
    'product_repository_stampprinted_getserialbyproduce':'product/repository/stampprinted/getserialbyproduce?produce_id=',
    'product_repository_selfprint_getallstamp':'product/repository/selfprint/getallstamp'
};

export var STANDAR_URLS = {
    'standar_getgroups':'standar/getgroups',
    'standar_getstandar':'standar/getstandar',
    'standar_check':'standar/check',
    'standar_infos':'standar/infos',
    'standar_uploadimage':'standar/uploadimage',
    'standar_removeimage':'standar/removeimage'
};

export var STORE_URLS = {
    'store_getitemsells':'store/getitemsells',
    'store_completeorder':'store/completeorder',
    'payment_momo':'payment/momo',
    'payment_momofail':'payment/momofail'
};

export var WAREHOUSE_URLS = {
    'warehouse_getstructure':'warehouse/getstructure',
    'warehouse_getmaterialuseful':'warehouse/getmaterialuseful',
    'warehouse_getproductionmaterialtype':'warehouse/getproductionmaterialtype',
    'warehouse_getlabeltype':'warehouse/getlabeltype',
    'warehouse_getpackagematerialtype':'warehouse/getpackagematerialtype',
    'warehouse_getpackageproducttype':'warehouse/getpackageproducttype',
    'warehouse_getpreservationtype':'warehouse/getpreservationtype',
    'warehouse_volumnunit':'warehouse/volumnunit',
    'warehouse_moneyunit':'warehouse/moneyunit',
    'warehouse_getstaff':'warehouse/getstaff',
    'warehouse_create':'warehouse/create',
    'warehouse_findall':'warehouse/findall',
    'warehouse_edit':'warehouse/edit',
    'warehouse_images':'warehouse/images',
    'warehouse_upload_image':'warehouse/upload/image',
    'warehouse_images_remove':'warehouse/images/remove'
};