import { Component, OnInit, Input, Output, ElementRef, ViewChild, Directive, AfterViewInit, EventEmitter } from '@angular/core';
declare var $: any;
declare var Inputmask: any;

@Component({
  selector: 'app-input-text-mask',
  templateUrl: './input-text-mask.component.html',
  styleUrls: ['./input-text-mask.component.css']
})
export class InputTextMaskComponent implements AfterViewInit {

  @ViewChild('inputChild') inputChild: ElementRef;

  inputValue: string;

  @Input('value')
  set _inputValue(value: string) {
    this.inputValue = value;
    if (this.inputChild) {
      Inputmask(this.inputMash).mask(this.inputChild.nativeElement);
    }
  } get _inputValue() {
    return this.inputValue;
  }

  @Input('mask')
  inputMash: any;

  @Output('emitValue')
  emitValue = new EventEmitter<string>();

  constructor() { }

  ngAfterViewInit() {
    Inputmask(this.inputMash).mask(this.inputChild.nativeElement);
    //$(this.inputChild.nativeElement).inputmask(this.inputMash);
  }

  changeData(event) {
    // console.log(event.target.value);

    // console.log(this.inputChild.nativeElement);
    //console.log(this.inputChild.nativeElement.inputmask.unmaskedvalue());
    var data = this.inputChild.nativeElement.inputmask.unmaskedvalue();
    //var data = $(this.inputChild.nativeElement).inputmask('unmaskedvalue');
    //var unformattedDate = Inputmask.unmask("23/03/1973", { alias: "dd/mm/yyyy"}); //23031973
    this.emitValue.emit(data)
  }

}
