import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlAPI,UrlAccountAPI, httpOption, httpOptionFormData, USER_LOCAL_KEY, APPLICATION_UID, APPLICATION_LOCAL_KEY } from '../settings/config';
import { ACCOUNT_URLS } from '../settings/route_urls';
import { UserObject } from '../models/data.models';
import { DGKLanguageService } from './language.service';
import { Subject } from 'rxjs';
import { deCryptAES, enCryptAES } from '../settings/dgkencrypt';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userInfo: UserObject;
    public userKey = USER_LOCAL_KEY;

    application_id = 1;

    UserSubject: Subject<UserObject> = new Subject<UserObject>();

    constructor(private httpClient: HttpClient, private languageService: DGKLanguageService, private router: Router) {
        var dataEnCrypted = localStorage.getItem(this.userKey);

        if (dataEnCrypted) {
            try {
                this.userInfo = JSON.parse(deCryptAES(dataEnCrypted));
            } catch {
                this.userInfo = null;
                localStorage.removeItem(this.userKey);
            }
        }

        this.UserSubject.next(this.userInfo);
    }

    sendRequireResetPassword(email) {
        return this.httpClient.post(UrlAPI + ACCOUNT_URLS.send_require_reset_password, {
            'email': email
        }, httpOption('', this.languageService.language_id));
    }


    sendMonitor() {
        return this.httpClient.get(UrlAPI + 'monitor', httpOption(this.userInfo.access_token, this.languageService.language_id));
    }

    sendResetPassword(token, password, repassword) {
        return this.httpClient.post(UrlAPI + ACCOUNT_URLS.reset_password, {
            'token': token,
            'password': password,
            'repassword': repassword
        }, httpOption('', this.languageService.language_id));
    }

    activeDemoCode(data) {
        return this.httpClient.post(UrlAPI + ACCOUNT_URLS.active_demo, data, httpOption('', this.languageService.language_id));
    }
    
    getInfos() {
        return this.httpClient.get(UrlAPI + ACCOUNT_URLS.account_info, httpOption('', this.languageService.language_id));
    }

    getBasicInfo() {
        return this.httpClient.get(UrlAPI + "account/basicinfo", httpOption(this.userInfo.access_token, this.languageService.language_id));
    }

    createContract(data) {
        //console.log(data);
        return this.httpClient.post(UrlAPI + 'account/contract/create', data, httpOption(this.userInfo.access_token, this.languageService.language_id));
    }

    
    signin(email, password, token) {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        formData.append('grant_type', 'password');

        return this.httpClient.post(UrlAPI + 'account/signin', formData, httpOptionFormData('', this.languageService.language_id, token));
    }
    
    signinConfirm(authorization_code, redirect_uri, request_id, token) {
        return this.httpClient.post(UrlAPI + 'account/signinsso', {
            'authorization_code': authorization_code,
            'redirect_uri': redirect_uri,
            'request_id': request_id
        }, httpOption('', this.languageService.language_id, token));
    }


    changePasswordFirst(password) {
        return this.httpClient.post(UrlAccountAPI + "auth/client/changepasswordfirst",{
            'password': password
        }, httpOption(this.userInfo.access_token, this.languageService.language_id));
    }

    observableMonitorTimer() {
        return timer(1000,1000*60*5);
    }

    updateMonitorDetail(function_name) {
        return this.httpClient.post(UrlAPI + 'monitordetail', {
            "function_name": function_name
        },httpOption(this.userInfo.access_token, this.languageService.language_id));
    }
    
    saveLogin(data) {
        this.userInfo = data;
        var encrypt = enCryptAES(JSON.stringify(this.userInfo));
        localStorage.setItem(this.userKey, encrypt);
        this.UserSubject.next(this.userInfo);
    }


    contractSuccessCreated() {
        this.userInfo = null;
        localStorage.removeItem(this.userKey);
        this.UserSubject.next(this.userInfo);
        this.router.navigate(['/user/contract/success']);
    }

    logout() {
        this.userInfo = null;
        localStorage.removeItem(this.userKey);
        this.UserSubject.next(this.userInfo);
        this.router.navigate(['/user/signin']);
    }


    redirectLogin() {
        this.router.navigate(['/user/signin']);
    }

    setApplicationRoleId(applicationRoleId) {
        localStorage.setItem(APPLICATION_LOCAL_KEY, applicationRoleId + '');
    }

    getApplicationRoleId() {
        return Number(localStorage.getItem(APPLICATION_LOCAL_KEY));
    }

    sendCheckCustomerStatus() {
        return this.httpClient.get(UrlAPI + 'account/checkstatus', httpOption(this.userInfo.access_token, this.languageService.language_id));
    }

    updateStatus(status_code) {
        //this.userInfo.local_status = status_code;
        var encrypt = enCryptAES(JSON.stringify(this.userInfo));
        localStorage.setItem(this.userKey, encrypt);
        this.UserSubject.next(this.userInfo);
    }
}