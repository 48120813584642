import { Directive, ElementRef } from '@angular/core';
declare var $: any;
@Directive({
  selector: '[appMagnificShowImage]',
})
export class MagnificShowImageDirective {

  constructor(el: ElementRef) {
    $(el.nativeElement).magnificPopup({type:'image'});
  }

}