import { Pipe, PipeTransform } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Pipe({
    name: 'firebaseGetPath',
    pure: false
})
export class FirebaseGetPathtPipe implements PipeTransform {
    private cachedUrl = '';
    private urlDownload = '';
    constructor(private firebaseService: FirebaseService) {

    }

    transform(url: string, server_upload: string): any {
        if (url !== this.cachedUrl) {
            this.cachedUrl = url;
            if (server_upload === 'firebase') {
                this.firebaseService.getDownloadUrl(url).subscribe((p: string) => {
                   
                    this.urlDownload = p;
                });
            } else {
                this.urlDownload = server_upload + url;
            }
           
        }
        //console.log(this.urlDownload);
        return this.urlDownload;

    }
}
