import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements AfterViewInit {

  @ViewChild('datetime', { static: false }) datePicker: ElementRef;


  @Input()
  isDisable = false;

  @Input()
  dateValue = new Date();

  @Output('submitChange')
  submitChange: EventEmitter<any> = new EventEmitter();

  @Input()
  dateFormat = 'DD/MM/YYYY';

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    $(this.datePicker.nativeElement).datetimepicker({
      format: this.dateFormat,
      defaultDate: this.dateValue,
      locale: 'en',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    }).on('dp.change', (e) => {
      // console.log('datepicker: ' + JSON.stringify($(this.datePicker.nativeElement).data('DateTimePicker').date()));
      this.submitChange.emit({
        'dateChange': $(this.datePicker.nativeElement).val(),
        'dateValue': $(this.datePicker.nativeElement).data('DateTimePicker').date()
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.datePicker && changes.dateValue) {
      //console.log(changes.dateValue.currentValue);
      $(this.datePicker.nativeElement).data("DateTimePicker").date(changes.dateValue.currentValue);
    }

  }

  change() {

  }
}
