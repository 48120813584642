declare var $: any;
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
declare var grecaptcha: any;
import { ResultInfo } from '../models/data.models';
import { reCATCHAKey, USER_LOCAL_KEY } from '../settings/config';


export function showLoading(isShow) {

    if (isShow) {
        $("#loader").css("display", "block");
    } else {
        $("#loader").css("display", "none");
    }
}

export function showModel(id) {

    $('#' + id).appendTo("body").modal('show');
}

export function hideModel(id) {
    $('#' + id).modal('hide');
}

export function showModelWithEl(elment) {

    $(elment).appendTo("body").modal('show');
}

export function hideModelWithEl(element) {
    $(element).modal('hide');
}

export function showNotify(msg) {
    $.notify({
        title: "Notify",
        message: msg
    });
}



export function showAlert(message) {
    Swal.fire({
        title: 'Alert!',
        text: message,
        icon: 'info'
    });
}



export function showAlertSuccess(message) {
    Swal.fire({
        title: 'Success!',
        text: message,
        icon: 'success'
    });
}


export function showError(message) {
    Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error'
    });
}

export function showAcceptAlert(tile, messenger, callBack: Function) {
    Swal.fire({
        title: tile,
        icon: 'info',
        text: messenger,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
            'Accept',
        cancelButtonText:
            'Cancel',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callBack();
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
};


export function showPermissAccessAlert(callBack: Function) {
    Swal.fire({
        title: "Error Access",
        icon: 'error',
        text: "You don't have permission to view this page",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
            'Change account',
        cancelButtonText:
            'Cancel',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callBack();
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
};


export function showSignUpAlert(callBack: Function) {
    Swal.fire({
        title: "Member",
        icon: 'info',
        text: "do you continue ?",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
            'Accept and join',
        cancelButtonText:
            'Cancel',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callBack();
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
};


export function callServiceHttp(serviceCall: Observable<Object>, callBack: Function) {
    serviceCall.subscribe((p: ResultInfo) => {
        showLoading(false);
        if (p.result === 'success') {
            callBack(p);
        } else if (p.result === 'error_params') {
            showError('Error paser info');
        } else if (p.result === 'error_bidding_has_apply') {
            showError('You has applied this bid, wait for response');
        } else if (p.result === 'error_server') {
            showError('Something look wrong');
        } else if (p.result === 'error_auth') {
            showError('login fail');
        } else if (p.result === 'error_password_wrong_format') {
            showError('Password wrong format');
        } else if (p.result === 'error_email_format_wrong') {
            showError('Email wrong format');
        } else if (p.result === 'error_verify_captcha') {
            showError('Wrong check session');
        } else if (p.result === 'error_verify_otp') {
            showError('OPT wrong');
        } else if (p.result === 'error_email_used') {
            showError('Email has used');
        } else if (p.result === 'error_client_authoziration') {
            showError('error client authorization');
        } else if (p.result === 'error_account_is_pending') {
            showError('Tài khoản đang đợi duyệt');
        } else if (p.result === 'error_product_active_notequal_quantities') {
            showError('Số lượng tồn kho sản phẩm và tem không hợp lệ');
        } else if (p.result === 'error_product_expired_field_empty') {
            showError('Bạn chưa khai thông tin số ngày hết hạn trong sản phẩm');
        }
        else {
            showError('Lỗi internet hoặc 3G');
        }
    }, (error: any) => {
        showLoading(false);
        switch (error.status) {
            case 403:
                showAcceptAlert("Hết hạn đăng nhập", "Bạn cần đăng nhập lại để tiếp tục thao tác!!!", () => {
                    localStorage.removeItem(USER_LOCAL_KEY);
                    window.open('/user/signin');
                });
                break;
            case 500:
                showError('Request not valid');
                break;
            case 404:
                showError('Do you choose wrong way ?');
                break;
            default:
                showError('Something is not right, please try agian');
                break;
        }
    });
}


export function change_alias(alias) {
    var str = alias;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/ + /g, " ");
    //str = str.trim();

    str = str.split(' ').join('-');

    return str;
}


export function checkPhone(phoneNumber) {
    let regexp = /^\+?\d{1,3}?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;
    return regexp.test(phoneNumber);
}


export function checkEmail(email) {
    let regexp = /^[a-z][a-z0-9_\.]{5,32}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/;
    console.log('Email ' + regexp.test(email));
    return regexp.test(email);
}


export function checkStrongPassword(password) {
    let regexp = /^(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/;

    return regexp.test(password);
}

export function checkDate(data) {
    let regexp = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return regexp.test(data);
}


export function callGetCaptcha(callback: Function) {
    /*
    grecaptcha.ready(() => {
        grecaptcha.execute(reCATCHAKey, { action: 'submit' }).then((token) => {
            callback(token);
        });
    });*/
    callback('');
}
