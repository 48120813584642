import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlAPI, httpOption, UrlAccountAPI, UrlUpload } from '../settings/config';
import { CUSTOMER_URLS } from '../settings/route_urls';
import { UserService } from './user.service';
import { DGKLanguageService } from './language.service';
import { CustomerObject, UserObject } from '../models/data.models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    userInfo: UserObject;
    languageId: number;
    languageCode: String;

    constructor(private httpClient: HttpClient, private userService: UserService, private languageService: DGKLanguageService) {
        this.userInfo = this.userService.userInfo;
        this.languageId = this.languageService.language_id;

        this.userService.UserSubject.subscribe((p: UserObject) => {
            this.userInfo = p;
        })
        this.languageService.languageChange.subscribe((p: string) => {
            this.languageCode = p;
        });
    }

    getBusinessInfos() {
        return this.httpClient.get(UrlAccountAPI + 'company/info', httpOption(this.userInfo.access_token, this.languageId));
    }


    checkPubicToOCOPHatinh() {
        return this.httpClient.get(UrlAPI + 'customer/checkpublicocophatinh',  httpOption(this.userInfo.access_token, this.languageId));
    }
    changePublicToOCOPHainh(isPublic: number) {
        return this.httpClient.post(UrlAPI + 'customer/changepublicocophatinh', {
            'is_public': Number(isPublic)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    updateCustomerType(type_id, is_check) {
        return this.httpClient.post(UrlAPI + 'customer/update/customertype', {
            'type_id': Number(type_id),
            'is_check': is_check
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    
    uploadLogoCompany(image, extension, mimetype): Observable<any> {

        let formData = new FormData();

        if (image) {
            formData.append('images', image, image.name);
        }

        formData.append('minetype', mimetype);
        formData.append('extension', extension);

        return this.httpClient.post(UrlAPI + 'customer/logo/upload', formData, httpOption(this.userInfo.access_token, this.languageId));
    }

    getRepresentative() {
        return this.httpClient.get(UrlAPI + 'company/representative/get', httpOption(this.userInfo.access_token, this.languageId));
    }

    updateEnterprise(data: CustomerObject) {
        return this.httpClient.post(UrlAccountAPI + CUSTOMER_URLS.update_infos, {
            'company_name': data.company_name,
            'company_name_en': data.company_name_en,
            'address': data.address,
            'phone_number': data.phone_number,
            'email': data.email,
            'province_id': Number(data.province_id),
            'tax_code': data.tax_code,
            'acronym_name': data.acronym_name,
            'website': data.website,
            'fanpage': data.fanpage,
            'founded_year': Number(data.founded_year),
            'number_of_employees': data.number_of_employees,
            'number_of_employees_partime': data.number_of_employees_partime,
            //"business_style_id": Number(data.business_style_id),
            'business_type_id': Number(data.business_type_id),
            'cocoop_name': data.cocoop_name,
            'main_products': data.main_products,
            'company_advantages': data.company_advantages,
            'district_id': Number(data.district_id),
            'trademarks': data.trademarks,
            'latitude': data.latitude,
            'longitude': data.longitude
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    getCustomerTypes(group_id) {
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.getcustomer_type, {
            'group_id': Number(group_id)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    getCustomerExportData() {
        return this.httpClient.get(UrlAccountAPI + 'company/capability/export', httpOption(this.userInfo.access_token, this.languageId));
    }


    updateCustomerTerm(type_id, is_check, type_code) {

        var url = '';

        if (type_code === 'delivery') {
            url = 'company/capability/export/update/deliveryterms';
        }

        if (type_code === 'payment') {
            url = 'company/capability/export/update/paymentterms';
        }

        if (type_code === 'currency') {
            url = 'company/capability/export/update/currencyaccept';
        }

        if (type_code === 'language') {
            url = 'company/capability/export/update/languagespoken';
        }

        return this.httpClient.post(UrlAccountAPI + url, {
            'type_id': type_code === 'currency' ? type_id : Number(type_id),
            'is_check': is_check
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    updateMainMarket(type_id, percent) {
        return this.httpClient.post(UrlAccountAPI + 'company/capability/export/update/mainmarket', {
            'type_id': Number(type_id),
            'percent': percent
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    updateCertificateImage(cer_id, documentName, image, extension, mimetype) {

        let formData = new FormData();

        if (image) {
            formData.append('images', image, image.name);
        }

        formData.append('cer_id', cer_id);
        formData.append('minetype', mimetype);
        formData.append('extension', extension);
        formData.append('document_name', documentName);
        return this.httpClient.post(UrlUpload + CUSTOMER_URLS.certificate_upload_image, formData, httpOption(this.userInfo.access_token, this.languageId));
    }

    removeCertificateImage(id) {

        let formData: FormData = new FormData();

        formData.append('id', id);
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.certificate_remove_image, formData, httpOption(this.userInfo.access_token, this.languageId));
    }

    getCertificateImages(license_id) {
        let formData: FormData = new FormData();

        formData.append('cer_id', license_id);

        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.certificate_images, formData, httpOption(this.userInfo.access_token, this.languageId));
    }


    createStaff(data) {
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.staff_create, data, httpOption(this.userInfo.access_token, this.languageId));
    }

    createStaffGroup(group_name) {
        return this.httpClient.post(UrlAPI + 'customer/staff/group/create', {
            'group_name': group_name
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    findStaffInGroup(group_id) {
        return this.httpClient.post(UrlAPI + 'customer/staff/group/findstaffs', {
            'group_id': Number(group_id)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    editStaffGroup(id, group_name) {
        return this.httpClient.post(UrlAPI + 'customer/staff/group/edit', {
            'group_name': group_name,
            'id': Number(id)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }
    findAllStaff() {
        return this.httpClient.get(UrlAPI + CUSTOMER_URLS.staffs, httpOption(this.userInfo.access_token, this.languageId));
    }

    findDepartments() {
        return this.httpClient.get(UrlAPI + 'customer/staff/groups', httpOption(this.userInfo.access_token, this.languageId));
    }

    findStaffGroups(staffId) {
        return this.httpClient.post(UrlAPI + 'customer/staff/staffgroups', {
            'staff_id': Number(staffId)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    editStaff(data) {
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.staff_edit, data, httpOption(this.userInfo.access_token, this.languageId));
    }
    findAllStaffActivate() {
        return this.httpClient.get(UrlAPI + 'customer/staff/list', httpOption(this.userInfo.access_token, this.languageId));
    }
    addStaffToGroup(staff_id, group_id, is_add) {
        return this.httpClient.post(UrlAPI + 'customer/staff/addgroup', {
            'staff_id': Number(staff_id),
            'group_id': Number(group_id),
            'is_add': is_add
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    updateRepresentative(data) {
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.representative_update, data, httpOption(this.userInfo.access_token, this.languageId));
    }

    changePassStaff(staffId, password) {
        return this.httpClient.post(UrlAPI + 'customer/staff/changepass', {
            'staff_id': Number(staffId),
            'password': password
        },httpOption(this.userInfo.access_token, this.languageId));
    }

    uploadIdentityImage(image, id, type) {

        let formData = new FormData();

        if (image) {
            formData.append('images', image, image.name);
        }
        formData.append('type_identity', type);
        formData.append('representative_id', id);


        return this.httpClient.post(UrlUpload + CUSTOMER_URLS.representative_upload_image, formData, httpOption(this.userInfo.access_token, this.languageId));
    }

    updateStaffUser(staff_id, password) {
        return this.httpClient.post(UrlAPI + CUSTOMER_URLS.staff_set_password, {
            'staff_id': Number(staff_id),
            'password': password
        }, httpOption(this.userInfo.access_token, this.languageId));
    }


    findCaptitalSources() {
        return this.httpClient.get(UrlAccountAPI + 'company/info/capitalsource', httpOption(this.userInfo.access_token, this.languageId));
    }

    addCapitalSource(data) {
        return this.httpClient.post(UrlAccountAPI + 'company/info/capitalsource/add', data, httpOption(this.userInfo.access_token, this.languageId));
    }

    removeCapitalSource(id) {
        return this.httpClient.post(UrlAccountAPI + 'company/info/capitalsource/remove', {
            'capital_id': Number(id)
        }, httpOption(this.userInfo.access_token, this.languageId));
    }
}


