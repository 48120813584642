import { HttpHeaders, HttpParams } from '@angular/common/http';

export const UrlAPI = 'https://longanapi.dgk.vn/api/enterprice/';
export const UrlUpload = 'https://longanapi.dgk.vn/api/enterprice/';
export const UrlAccountAPI = 'https://longanapi.dgk.vn/api/account/';
export const UrlDiary = 'https://longanapi.dgk.vn/api/diary/';

// important
export const CLIENT_UID = '392d2271-828c-11eb-93ec-0050569c60ce';

export const APPLICATION_UID = '513d447e-a61e-11ea-8e6d-0050569c60ce';

export const reCATCHAKey = '6LekdYUaAAAAAGjtJ8kAOFWDZm6mv5X5I8Styrok';

export const LOGIN_REDIRECT = 'http://localhost:4300/auth/client/signin'

export const REDIRECT_URI = 'http://localhost:4200/user/signin/confirm';

export const isNeedContract = false;

export class Constants {
  static readonly DATE_FMT = 'dd/MM/yyyy';
  static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
}

export function httpOption(access_token, language_id, captchaToken = '') {

 // var role = localStorage.getItem(APPLICATION_LOCAL_KEY);

  return {
    headers: new HttpHeaders({
      'x-client-language': String(language_id),
      'x-access-type': '1',
      'x-access-token': access_token,
      'x-client-random-key': '',
      'x-client-uid': CLIENT_UID,
      'x-application-uid': APPLICATION_UID,
      'x-captcha-token': captchaToken,
      'x-application-role': '1'
    })
  };
}

export function httpOptionFormData(access_token, language_id, captchaToken = '') {
  //var role = localStorage.getItem(APPLICATION_LOCAL_KEY);
  return {
    headers: new HttpHeaders({
      'x-client-language': String(language_id),
      'x-access-type': '1',
      'x-access-token': access_token,
      'x-client-random-key': '',
      'x-client-uid': CLIENT_UID,
      'x-application-uid': APPLICATION_UID,
      'x-captcha-token': captchaToken,
      'x-application-role':  '1'
    })
  };
}


export function httpOptionParames(p, access_token, language_id, captchaToken = '') {
  //var role = localStorage.getItem(APPLICATION_LOCAL_KEY);
  return {
    headers: new HttpHeaders({
      'x-client-language': String(language_id),
      'x-access-type': '1',
      'x-access-token': access_token,
      'x-client-random-key': '',
      'x-client-uid': CLIENT_UID,
      'x-application-uid': APPLICATION_UID,
      'x-captcha-token': captchaToken,
      'x-application-role': '1'
    }),
    params: p
  };
}

export function httpOptionFileDownloadParames(p, access_token, language_id, captchaToken = '') {
  //var role = localStorage.getItem(APPLICATION_LOCAL_KEY);
  return {
    responseType: 'arraybuffer' as 'arraybuffer',
    headers: new HttpHeaders({
      'x-client-language': String(language_id),
      'x-access-type': '1',
      'x-access-token': access_token,
      'x-client-random-key': '',
      'x-client-uid': CLIENT_UID,
      'x-application-uid': APPLICATION_UID,
      'x-captcha-token': captchaToken,
      'x-application-role': '1'
    }),
    params: p
  };
}

export const LOGO_FULL = '/assets/img/logo_hatinh.png';


export const USER_LOCAL_KEY = 'user_1231212313qweqwdas';

export const APPLICATION_LOCAL_KEY = 'application_123121weqwdasdaesa2qdas';

export const WEBSITE_WORK_TASK = 'https://calendar.2vn.vn';

export const LOCATION_DEFAULT = {
  'country_code' : 'VN',
  'province_id': 3808
};

export const folder_images = 'hatinh/';

export const SEND_MONITOR = false;

export const COLLECTION_ROLE_MENU = 'hatinh_menu_roles/';