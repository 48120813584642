import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UrlAPI, httpOption, httpOptionParames, UrlUpload} from '../settings/config';
import {PRODUCT_URLS} from '../settings/route_urls';
import {ProductObject, UserObject} from '../models/data.models';
import {UserService} from './user.service';
import {DGKLanguageService} from './language.service';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  userInfo: UserObject;
  languageId: number;
  languageCode: String;

  constructor(private httpClient: HttpClient, private userService: UserService, private languageService: DGKLanguageService) {
    this.userInfo = this.userService.userInfo;
    this.languageId = this.languageService.language_id;

    this.userService.UserSubject.subscribe((p: UserObject) => {
      this.userInfo = p;
    })
    this.languageService.languageChange.subscribe((p: string) => {
      this.languageCode = p;
    });
  }

  productGroupRegister(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_productgroup_newgroup, data, httpOption(this.userInfo.access_token, this.languageId));
  }

  productGroupEdit(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_productgroup_productgroupedit, data, httpOption(this.userInfo.access_token, this.languageId));
  }

  productGroupAddFarm(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_productgroup_addfarm, data, httpOption(this.userInfo.access_token, this.languageId));
  }

  getListProductGroups() {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_productgroup_getlistproductgroups, httpOption(this.userInfo.access_token, this.languageId));
  }

  getListProductGroupForSearch() {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_productgroup_getlistforsearch, httpOption(this.userInfo.access_token, this.languageId));
  }

  getAllProducts() {
    return this.httpClient.get(UrlAPI + 'product/filter/all', httpOption(this.userInfo.access_token, this.languageId));
  }

  getProductGroupInfos(id) {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_productgroup_productgroupinfos, httpOptionParames({
      'group_id': id
    }, this.userInfo.access_token, this.languageId));
  }

  createProduct(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_createproduct, data, httpOption(this.userInfo.access_token, this.languageId));
  }

  getAllProductBySku(skuid) {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_getallproductbygroup, httpOptionParames({
      'group_id': skuid
    }, this.userInfo.access_token, this.languageId));
  }

  searchByGroup(groupId) {
    return this.httpClient.post(UrlAPI + 'product/search/bygroup', {
      'group_id': Number(groupId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  searchOCOPByGroup(groupId) {
    return this.httpClient.post(UrlAPI + 'product/search/ocop/bygroup', {
      'group_id': Number(groupId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  searchProductInfoSimple(productId) {
    return this.httpClient.post(UrlAPI + 'product/search/productinfo/simple', {
      'product_id': Number(productId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  changeShareOCOPHaTinh(isPublic: number, productId: number) {
    return this.httpClient.post(UrlAPI + 'product/changeshareocophatinh', {
      'product_id': Number(productId),
      'is_public': Number(isPublic)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  getProductInfo(id) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_getproductinfos, {
      'product_id': Number(id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProduct(data: ProductObject) {
    //data.star_rate_number = Number(data.star_rate_number);
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_updateproduct,
      {
        "id": Number(data.id),
        "product_name": data.product_name,
        "product_code": data.product_code,
        "price": Number(data.price),
        "currency_id": data.currency_id,
        "address": data.address,
        "website": data.website,
        "shopping_link": data.shopping_link,
        "youtube": data.youtube,
        "email": data.email,
        "phone_contact": data.phone_contact,
        "quantity": data.quantity,
        "unit_id": Number(data.unit_id),
        "product_weight": data.product_weight,
        "product_weight_unit": Number(data.product_weight_unit),
        "dimension_packing": data.dimension_packing,
        "dimension_packing_unit": Number(data.dimension_packing_unit),
        "product_expire": data.product_expire,
        "product_expire_unit": Number(data.product_expire_unit),
        "period_after_open": data.period_after_open,
        "period_after_open_unit": Number(data.period_after_open_unit),
        "from_temperature_warehourse_celsius": Number(data.from_temperature_warehourse_celsius),
        "to_temperature_warehourse_celsius": Number(data.to_temperature_warehourse_celsius),
        "gs1_code": data.gs1_code,
        "condition_transfer": data.condition_transfer,
        "condition_warehourse": data.condition_warehourse,
        "provider": data.provider,
        "group_id": Number(data.group_id),
        "sku": data.sku
      }, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProductCommon(product_id, common_id, is_check) {
    return this.httpClient.post(UrlAPI + 'product/updatecommonproduct', {
      'product_id': Number(product_id),
      'common_id': Number(common_id),
      'is_check': is_check
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  traceBarcodeInfo(id) {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_traceability, httpOptionParames({
      'barcode': id
    }, this.userInfo.access_token, this.languageId));
  }


  findProductImages(product_id) {
    return this.httpClient.post(UrlAPI + 'product/findproductimages', {
      'product_id': Number(product_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProductImage(id, image) {

    let formData: FormData = new FormData();

    if (image) {
      formData.append('images', image, image.name);
    }

    formData.append('id', id);

    return this.httpClient.post(UrlUpload + PRODUCT_URLS.product_uploadproductimage, formData, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProductThumbnail(product_id, image_id) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_uploadthumbnail, {
      'product_id': Number(product_id),
      'image_id': Number(image_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  removeProductImage(id) {

    let formData: FormData = new FormData();

    formData.append('id', id);
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_removeproductimage, formData, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProductDesciption(id, description, shortDescription) {

    let formData: FormData = new FormData();

    formData.append('id', id);
    formData.append('description', description);
    formData.append('short_descriptions', shortDescription);

    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_updateproductdescription, formData, httpOption(this.userInfo.access_token, this.languageId));
  }


  setSKUStandar(standarId, skuId, isCheck) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_productgroup_setproductgroupstandar, {
      id: Number(skuId),
      standar_cus_id: Number(standarId),
      is_check: isCheck ? 1 : 0
    }, httpOption(this.userInfo.access_token, this.languageId));

  }

  createMakeCopy(data) {
    data['group_id'] = Number(data['group_id']);
    data['product_id'] = Number(data['product_id']);
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_makecopy, data, httpOption(this.userInfo.access_token, this.languageId));
  }


  reviewProduct(product_id) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_review, {
      'product_id': Number(product_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  addSpecies(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_species_add, data, httpOption(this.userInfo.access_token, this.languageId));
  }


  getAllSpecies() {
    return this.httpClient.get(UrlAPI + PRODUCT_URLS.product_species_getall, httpOption(this.userInfo.access_token, this.languageId));
  }


  approveAdvertise(data) {
    return this.httpClient.post(UrlAPI + PRODUCT_URLS.product_promotion_set, data, httpOption(this.userInfo.access_token, this.languageId));
  }


  findStandars(product_id) {
    return this.httpClient.post(UrlAPI + 'product/standars', {
      'product_id': Number(product_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findPartner(product_id, partner_type_id) {
    return this.httpClient.post(UrlAPI + 'product/partner/get', {
      'product_id': Number(product_id),
      'partner_type_id': Number(partner_type_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  checkPartner(product_id, partner_id, is_check) {
    return this.httpClient.post(UrlAPI + 'product/partner/check', {
      'product_id': Number(product_id),
      'partner_id': Number(partner_id),
      'is_check': is_check
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  checkStandar(product_id, certificate_customer_id, is_check) {
    return this.httpClient.post(UrlAPI + 'product/standar/check', {
      'product_id': Number(product_id),
      'certificate_customer_id': Number(certificate_customer_id),
      'is_check': is_check
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  uploadStandarImage(image, standar_product_id) {

    let formData = new FormData();

    if (image) {
      formData.append('images', image, image.name);
    }
    formData.append('standar_product_id', standar_product_id);

    return this.httpClient.post(UrlAPI + 'product/standar/image/upload', formData, httpOption(this.userInfo.access_token, this.languageId));
  }

  findStandarImages(standar_product_id) {
    return this.httpClient.post(UrlAPI + 'product/standar/images', {
      'standar_product_id': Number(standar_product_id),
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  removeStandarImage(standar_product_id, image_id) {
    return this.httpClient.post(UrlAPI + 'product/standar/image/remove', {
      'standar_product_id': Number(standar_product_id),
      'image_id': Number(image_id)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findFarms(product_id) {
    return this.httpClient.post(UrlAPI + 'product/farms', {
      'product_id': Number(product_id),
      'farm_type_id': 1
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  checkFarm(product_id, farm_id, is_check) {
    return this.httpClient.post(UrlAPI + 'product/checkfarm', {
      'product_id': Number(product_id),
      'farm_id': Number(farm_id),
      'is_check': is_check
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  findProductWarehouseReport(productId) {
    return this.httpClient.post(UrlAPI + 'product/warehouse/report', {
      'product_id': Number(productId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findProductWarehouses(productId, statusId) {
    return this.httpClient.post(UrlAPI + 'product/warehouse/filter', {
      'product_id': Number(productId),
      'status_id': Number(statusId)
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  importProductWarehouses(productId, quantities, notes) {
    return this.httpClient.post(UrlAPI + 'product/warehouse/import', {
      'product_id': Number(productId),
      'quantities': Number(quantities),
      'notes': notes
    }, httpOption(this.userInfo.access_token, this.languageId));
  }


  filterSaleReportByMonth(data) {
    return this.httpClient.post(UrlAPI + 'salesreport/bymonth', data, httpOption(this.userInfo.access_token, this.languageId));
  }


  filterSaleReportByYear(data) {
    return this.httpClient.post(UrlAPI + 'salesreport/byyear', data, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateSaleReportByMonth(data) {
    return this.httpClient.post(UrlAPI + 'salesreport/update', data, httpOption(this.userInfo.access_token, this.languageId));
  }


  findProductInfoTranslate(productId: number, languageTranslate: number) {
    return this.httpClient.post(UrlAPI + 'product/translate/getbylanguage', {
      "product_id": productId,
      "change_language": languageTranslate
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  findProductInfoAutoTranslate(productId: number, languageTranslate: string) {
    return this.httpClient.post(UrlAPI + 'product/translate/auto', {
      "product_id": productId,
      "change_language": languageTranslate
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  updateProductTranslate(productId: number, languageTranslate: number, productName: string, conditionTransfer: string, conditionWarehourse: string, shortDescriptions: string) {

    return this.httpClient.post(UrlAPI + 'product/translate/update', {
      "product_id": productId,
      "change_language": languageTranslate,
      "product_name": productName,
      "condition_transfer": conditionTransfer,
      "condition_warehourse": conditionWarehourse,
      "short_descriptions": shortDescriptions
    }, httpOption(this.userInfo.access_token, this.languageId));

  }


  createProductArticle(data: any, image) {

    let formData: FormData = new FormData();

    if (image) {
      formData.append('images', image, image.name);
    }

    for (let key of Object.keys(data)) {
      formData.append(key, data[key]);
    }


    return this.httpClient.post(UrlAPI + 'product/article/add', formData, httpOption(this.userInfo.access_token, this.languageId));
  }

  getListProductArticles(productId: number) {
    return this.httpClient.get(UrlAPI + 'product/article/getlist?product_id=' + productId, httpOption(this.userInfo.access_token, this.languageId));
  }

  removeProductArticle(productId: number, articleId: number) {
    return this.httpClient.post(UrlAPI + 'product/article/delete', {
      'product_id': productId,
      'article_id': articleId
    }, httpOption(this.userInfo.access_token, this.languageId));
  }

  createProductionQuota(data: any) {
    return this.httpClient.post(UrlAPI + 'product/production/createquota', data, httpOption(this.userInfo.access_token, this.languageId));
  }

  findProductionQuota() {
    return this.httpClient.get(UrlAPI + 'product/production/findquota', httpOption(this.userInfo.access_token, this.languageId));
  }

  createProductionQuotaPlan(data: any) {
    return this.httpClient.post(UrlAPI + 'product/production/addquotaplan', data,  httpOption(this.userInfo.access_token, this.languageId))
  }

  findProductionQuotaPlan(quotaId: number) {
    return this.httpClient.post(UrlAPI + 'product/production/findquotaplan', {
      'quota_id': Number(quotaId)
    },  httpOption(this.userInfo.access_token, this.languageId))
  }

  findBuyer() {
    return this.httpClient.get(UrlAPI + 'ocophatinh/buyers',  httpOption(this.userInfo.access_token, this.languageId))
  }


  
  findBuyerInfo(code: string) {
    return this.httpClient.post(UrlAPI + 'ocophatinh/findbuyerinfo', {
      'code': code
    },  httpOption(this.userInfo.access_token, this.languageId))
  }

  findBuyerOrders(buyerId: number) {
    return this.httpClient.post(UrlAPI + 'ocophatinh/findbuyerorders', {
      'buyer_id': buyerId
    },  httpOption(this.userInfo.access_token, this.languageId));
  }

  updateBuyer(data: any) {
    return this.httpClient.post(UrlAPI + 'ocophatinh/updatebuyer', data,  httpOption(this.userInfo.access_token, this.languageId));
  }

  findOrders(code: string) {
    return this.httpClient.post(UrlAPI + 'ocophatinh/orders', {
      'order_code': code
    },  httpOption(this.userInfo.access_token, this.languageId));
  }


  findProductBought(productId: number) {
    return this.httpClient.post(UrlAPI + 'product/findbought', {
      'product_id': Number(productId)
    },httpOption(this.userInfo.access_token, this.languageId));
  }

  createProductBought(data: any) {
    return this.httpClient.post(UrlAPI + 'product/createbought', data,httpOption(this.userInfo.access_token, this.languageId));
  }

}
