import Swal from 'sweetalert2';
declare var $: any;

const actionButton = {
    'accept': 'Đồng ý', 'accept_en': 'Accept', 'cancel': 'Thôi', 'cancel_en': 'Cancel', 'alertinfo': 'Thông báo', 'alertinfo_en': 'Alert', 'alerterror': 'Cảnh báo', 'alerterror_en': 'Error'
}


export const messages_locale = {
    'form_valid': 'Vùi lòng nhập đủ thông tin cần thiết !',
    'form_valid_en': 'Please input all fields are required'
}

export function showAlert(message, language_prefix = '') {
    Swal.fire({
        title: actionButton['alertinfo' + language_prefix],
        text: message,
        icon: 'info'
    });
}

export function showError(message, language_prefix = '') {
    Swal.fire({
        title: actionButton['alerterror' + language_prefix],
        text: message,
        icon: 'error'
    });
}

export function showAlertWithTitle(title, message) {
    Swal.fire({
        title: title,
        text: message,
        icon: 'error'
    });
}


export function showAcceptAlert(title, message, callBack: Function, language_prefix = '') {

    Swal.fire({
        title: title,
        icon: 'info',
        text: message,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: actionButton['cancel' + language_prefix],
        confirmButtonText: actionButton['accept' + language_prefix],
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callBack();
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    })
}

export function showAcceptContract(message, callBack: Function, language_prefix = '') {

    Swal.fire({
        title: 'Xác Nhận Hợp Đồng',
        icon: 'info',
        text: message,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: 'Không đồng ý',
        confirmButtonText: 'Đồng ý ký',
        buttonsStyling: false
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            callBack();
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    });
}

export function showAcceptCancelAlert(title, message, callBack: Function, cancel: Function, language_prefix = '') {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: actionButton['cancel' + language_prefix],
        confirmButtonText: actionButton['accept' + language_prefix],
        buttonsStyling: false
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            
        } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
        }
    });
}


