import { AfterViewInit, Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { showAcceptAlert } from '../../components/alert.component';
import { ResultInfo, UserObject } from '../../models/data.models';
import { DGKLanguageService } from '../../services/language.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UserService } from '../../services/user.service';
import { LOGO_FULL, SEND_MONITOR, COLLECTION_ROLE_MENU } from '../../settings/config';
import { DOCUMENT } from '@angular/common';
import { callServiceHttp, showLoading, showModel } from '../../settings/utils';
import { DataService } from '../../services/data.service';
declare var $: any;
import { Observable, Subscription } from 'rxjs';
import { CustomerService } from '../../services/customer.service';

function global() {

  $('[data-bs-toggle="popover"]').popover();
  $('[data-bs-toggle="tooltip"]').tooltip(); // gives the scroll position


  // Form Validation
  var forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      }, false);
    });
}

function toggleSidebar() {
  $('body').toggleClass("sidebar-hidden");
};

export interface MenuInfo {
  uid: string,
  menu: ItemMenu[]
}

export interface ItemMenu {
  title: string,
  url: string
  is_dropdown: boolean,
  is_show: boolean,
  items: ItemMenu[]
}

@Component({
  selector: 'app-circl-layout',
  templateUrl: './circl-layout.component.html',
  styleUrls: ['./circl-layout.component.css']
})
export class CirclLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  language_prefix = '';
  logoSrc = LOGO_FULL;
  userInfo: UserObject;
  checkPublicInfo: any;
  menuItems: any[];
  menuSucription: Subscription;

  title = 'Hệ thống TXNG tỉnh Hà Tỉnh';

  private itemDoc: AngularFirestoreDocument<MenuInfo>;
  item: Observable<MenuInfo>;

  constructor(@Inject(DOCUMENT) private document: Document, private userService: UserService, private customerService: CustomerService,
    private languageService: DGKLanguageService, private afMessaging: AngularFireMessaging,
    private dataService: DataService, private afs: AngularFirestore) {

    /*
    this.afMessaging.messages
    .subscribe((message) => {
      console.log(message);

    });*/
    this.userInfo = this.userService.userInfo;

    this.userService.UserSubject.subscribe((p: UserObject) => {
      this.userInfo = p;
    });

    if (languageService.language_code === 'vi') {
      this.language_prefix = '';
    } else {
      this.language_prefix = '_en';
    }

    this.languageService.languageChange.subscribe((p: string) => {
      if (p === 'vi') {
        this.language_prefix = '';
      } else {
        this.language_prefix = '_en';
      }
    });
  }

  ngOnInit(): void {
    //this.createCssLink('maintheme', 'circletheme.css');
    this.itemDoc = this.afs.doc<MenuInfo>(COLLECTION_ROLE_MENU + this.userService.userInfo.user_code);
    /*
    this.menuSucription = this.itemDoc.valueChanges().subscribe((p: MenuInfo) => {
      if (!p) {
      
        this.dataService.getMenus().subscribe((p: any[]) => {
          this.menuItems = p;
          this.itemDoc.set({
            menu: p,
            uid: this.userService.userInfo.user_code
          });
          // this.menuSucription.unsubscribe();

        });
      } else {
        //console.log('ok...');
        this.menuItems = p.menu;
        console.log(JSON.stringify(p.menu));
        //this.menuSucription.unsubscribe();
      }
    });;


    if (SEND_MONITOR) {
      callServiceHttp(this.userService.sendMonitor(), (p: ResultInfo) => {

      });
    }
    */

    this.dataService.getMenus().subscribe((p: any[]) => {
      this.menuItems = p;

    });

    let roleId = Number(localStorage.getItem('role_id'));

    if (roleId == null || roleId == undefined) {
      roleId = 1;
    }
    if (roleId == 1) {
      this.title = 'Ứng dụng quản lý khâu Trồng trọt, Chăn nuôi';
    } else if (roleId == 2) {
      this.title = 'Ứng dụng  quản lý khâu Chế biến và Sản xuất';
    } else if (roleId == 3) {
      this.title = 'Ứng dụng quản lý Thương Mại Điện Tử';
    } else {
      this.title = 'Hệ thống TXNG tỉnh Hà Tỉnh';
    }
  }

  ngOnDestroy() {
    // this.menuSucription.unsubscribe();
  }


  checkChange() {
    this.itemDoc.update({
      menu: this.menuItems,
      uid: this.userService.userInfo.user_code
    });
  }

  ngAfterViewInit() {


    global();
  }

  changeLanguage(code) {
    this.languageService.changeLanguage(code);
  }
  logout() {
    this.languageService.getTranslate('layout.topbar.signout_alert').subscribe((res: string) => {
      showAcceptAlert('Alert', res, () => {
        this.userService.logout();
      }, this.language_prefix);
    });
  }

  toggleSidebarClick() {
    toggleSidebar();
  }

  createCssLink(linkId, cssfile) {
    const headEl = this.document.getElementsByTagName('head')[0]

    const existEl = this.document.getElementById(linkId) as HTMLLinkElement;

    if (existEl) {
      existEl.href = cssfile;
    } else {
      const linkEl = this.document.createElement('link');

      linkEl.rel = 'stylesheet';
      linkEl.href = cssfile;
      linkEl.id = linkId;
      headEl.appendChild(linkEl);
    }

  }


  showShareOCOPHatinhModal() {
    showLoading(true);
    callServiceHttp(this.customerService.checkPubicToOCOPHatinh(), (p: ResultInfo) => {
      showLoading(false);
      this.checkPublicInfo = p.reply;
      showModel('shareocophatinhmodal');
    });
  }

  changePublicOCOPHatinh(isPublic: number): void {
    showLoading(true);
    callServiceHttp(this.customerService.changePublicToOCOPHainh(isPublic), (p: ResultInfo) => {
      showLoading(false);
      this.checkPublicInfo = p.reply;
    });
  }

}
