import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LanguageObject } from '../models/data.models';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DGKLanguageService {

    language_id = 1;
    language_code = 'vi';

    languagePrefix = '';

    languageChange = new Subject<string>();

    languages: LanguageObject[] = [
        { "id": 1, "country_code": "VN", "language_code": "vi", "language_name": "Tiếng Việt" },
        { "id": 2, "country_code": "EN", "language_code": "en", "language_name": "Tiếng Anh" },
        { "id": 3, "country_code": "CN", "language_code": "zh", "language_name": "Tiếng Trung" },
        { "id": 5, "country_code": "JP", "language_code": "ja", "language_name": "Tiếng Nhật" },
        { "id": 10, "country_code": "FR", "language_code": "fr", "language_name": "Tiếng Pháp" }];


    constructor(private translateService: TranslateService, private httpClient: HttpClient) {
        this.translateService.use(this.language_code);
    }

    changeLanguage(language_code) {
        this.language_id = this.findLanguageId(language_code);
        this.language_code = language_code;
        this.translateService.use(this.language_code);


        if (language_code == 'vi') {
            this.languagePrefix = '';
        } else {
            this.languagePrefix = '_' + this.language_code;
        }

        this.languageChange.next(language_code);
    }

    getLanguageCode(languageId: number) {
        for(let item of this.languages) {
            if (item.id == languageId) {
                return item.language_code;
            }
        }

        return 'vi';
    }

    findLanguageId(language_code) {
        for (var item of this.languages) {
            if (item.language_code === language_code) {
                return item.id;
            }
        }

        return 1;
    }

    getTranslate(key) {
        return this.translateService.get(key);
    }

    autoTranslate(translateTo: string, content: string) {
        let url = `https://clients5.google.com/translate_a/t?client=dict-chrome-ex&sl=vi&tl=${translateTo}&q=${content}`;

        return this.httpClient.get(url);


    }
}