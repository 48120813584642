import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, SimpleChanges, OnChanges } from '@angular/core';

declare var ApexCharts: any;

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.css']
})
export class ApexChartComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('options')
  options: any;

  @ViewChild('apexChartView')
  apexChartView: ElementRef;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {

    //console.log(changes.options.currentValue);
    if (changes.chartType) {
      if (this.apexChartView) {
        var chart = new ApexCharts(
          this.apexChartView.nativeElement,
          this.options
        );

        chart.render();
      }
    }
  }

  ngAfterViewInit() {

    var chart = new ApexCharts(
      this.apexChartView.nativeElement,
      this.options
    );

    chart.render();
  }


}
