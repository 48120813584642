import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
declare var $: any;
declare var PerfectScrollbar: any;
const submenu_animation_speed = 200;


const sidebar = () => {
  var select_sub_menus = $('.accordion-menu li:not(.open) ul'),
    active_page_sub_menu_link = $('.accordion-menu li.active-page > a');

  // Hide all sub-menus
  select_sub_menus.hide();

  const container = document.querySelector('.page-sidebar');
  const ps = new PerfectScrollbar(container);


  // Accordion
  $('.accordion-menu li a').on('click', function (e) {
    var sub_menu = $(this).next('ul'),
      parent_list_el = $(this).parent('li'),
      active_list_element = $('.accordion-menu > li.open'),
      show_sub_menu = function () {
        sub_menu.slideDown(submenu_animation_speed);
        parent_list_el.addClass('open');
        ps.update();
      },
      hide_sub_menu = function () {
        sub_menu.slideUp(submenu_animation_speed);
        parent_list_el.removeClass('open');
        ps.update();
      },
      hide_active_menu = function () {
        $('.accordion-menu > li.open > ul').slideUp(submenu_animation_speed);
        active_list_element.removeClass('open');
        ps.update();
      };

    if (sub_menu.length) {
     

      if (!parent_list_el.hasClass('open')) {
        if (active_list_element.length) {
          hide_active_menu();
        };
        show_sub_menu();
      } else {
        hide_sub_menu();
      };

      return false;

    };
    // e.preventDefault();
    // return false;
  });

  if ($('.active-page > ul').length) {
    active_page_sub_menu_link.click();
  };

};
@Component({
  selector: 'app-slider-menu',
  templateUrl: './slider-menu.component.html',
  styleUrls: ['./slider-menu.component.css']
})
export class SliderMenuComponent implements OnInit, AfterViewInit, OnChanges {


  @Input('items')
  items = [];

  isChange = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    sidebar();
    this.isChange = true;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.items) {
      this.isChange =false;
    }

  }

  pipeList(items) {
    var menu = [];

    for (var item of items) {
      if (item.is_show) {
        menu.push(item);
      }
    }
    return menu;
  }

  ngAfterViewChecked() {
      if (!this.isChange) {
        sidebar();
        this.isChange = true;
      }
  }
}
