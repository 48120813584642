import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { folder_images } from '../settings/config';

@Injectable({ providedIn: 'root' })
export class FirebaseService {
    constructor(private storage: AngularFireStorage) { }


    uploadFile(fileUpload: File, callBack: Function) {
        const fileName = fileUpload.name;
        const filePath = folder_images + fileName;

        const fileRef = this.storage.ref(filePath);

        fileRef.put(fileUpload).then((snapshot) => {
            callBack(filePath);
        });

    }

    getDownloadUrl(filePath: string) {
        const ref = this.storage.ref(filePath);
        return ref.getDownloadURL();
    }

    deleteFile(filePath: string) {
        const ref = this.storage.ref(filePath);
        return ref.delete();
    }


    

}