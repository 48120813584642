import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
declare var $: any;
@Directive({
    selector: '[tagsinputDirective]',
})
export class TagsinputDirective implements OnChanges{

    @Input('tagsinputValue')
    tagsinputValue: string;

    elementT: ElementRef;

    constructor(el: ElementRef) {
        this.elementT = el;
        $(el.nativeElement).tagsinput();
        // el.nativeElement.style.backgroundColor = 'yellow';
    }

    ngOnChanges(changes: SimpleChanges) {
        var arr = this.tagsinputValue.split(',');
        for (var item of arr) {
            $(this.elementT.nativeElement).tagsinput('add', item);
        }
      
    }

}