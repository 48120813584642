<ul class="list-unstyled accordion-menu">

    <li>
        <a routerLink="/dashboard" style="font-weight: bold;"><i class="fas fa-chart-pie"></i> DASHBOARD</a>
    </li>
    <li *ngFor="let item of items;index as i">
        <a class="" *ngIf="item.is_dropdown && item.is_show" style="font-weight: bold;"
            [ngClass]="{'menu-disable': item.url === '/updating'}" href="javascript:void(0)"><span>{{i + 1}}</span>.
            {{item.title}} <i *ngIf="item.is_dropdown" class="fas fa-chevron-right dropdown-icon"></i></a>

        <a *ngIf="!item.is_dropdown && item.is_show" class="" style="font-weight: bold;"
            [ngClass]="{'menu-disable': item.url === '/updating'}" routerLink="{{item.url}}"><span>{{i + 1}}</span>.
            {{item.title}} </a>

        <ul class="" *ngIf="item.is_dropdown && item.is_show">
            <li *ngFor="let child of pipeList(item.items);index as childidx"><a
                    [ngClass]="{'menu-disable': child.url === '/updating'}" routerLink="{{child.url}}">{{i +
                    1}}.{{childidx +
                    1}}.
                    {{child.title}}</a></li>
        </ul>
    </li>
    <li class="sidebar-title">
        Hổ trợ
    </li>
    <li>
        <a href="javascript:void(0)"><i data-feather="phone-call"></i> Liên hệ CQQL</a>
    </li>
    <li>
        <a href="javascript:void(0)"><i data-feather="phone-call"></i> Hỗ trợ kỹ thuật</a>
    </li>
</ul>