import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UrlAPI, httpOption, httpOptionParames } from '../settings/config';
import { COMMON_DATA_URLS } from '../settings/route_urls';
import { UserService } from './user.service';
import { DGKLanguageService } from './language.service';
import { UserObject } from '../models/data.models';

@Injectable({
    providedIn: 'root'
})
export class DataService {


    userInfo: UserObject;
    languageId: number;
    languageCode: string;

    constructor(private httpClient: HttpClient, private userService: UserService, private languageService: DGKLanguageService) {
        this.userInfo = this.userService.userInfo;
        this.languageId = this.languageService.language_id;

        this.userService.UserSubject.subscribe((p: UserObject) => {
            this.userInfo = p;
        })
        this.languageService.languageChange.subscribe((p: string) => {
            this.languageCode = p;
        });
    }

    getAccessoriesGroups() {
        return this.httpClient.get('/assets/data/accessories_group.json');
    }

    getCountries() {
        return this.httpClient.get('/assets/data/countries.json');
    }

    getProvince(country_code) {
        return this.httpClient.get(UrlAPI + COMMON_DATA_URLS.get_provinces, httpOptionParames({
            'country': country_code
        }, '', this.languageId));
    }
    getDistrict(province) {
        return this.httpClient.get(UrlAPI + COMMON_DATA_URLS.get_districts, httpOptionParames({
            'province': province
        }, '', this.languageId));
    }


    getCurrencyType() {
        return this.httpClient.get('/assets/data/currencies.json');
    }

    getLanguages() {
        return this.httpClient.get('/assets/data/languages.json');
    }

    getCalculationUnit(unit_type) {
        return this.httpClient.post(UrlAPI + 'data/getCalculatorUnit', {
            'unit_type': unit_type
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    getCommonData(type_id) {
        return this.httpClient.post(UrlAPI + 'data/getCommonData', {
            'type_id': type_id
        }, httpOption(this.userInfo.access_token, this.languageId));
    }

    getCustomerTypes() {
        return this.httpClient.post(UrlAPI + 'data/getcustomertypes', {

        }, httpOption(this.userInfo.access_token, this.languageId));
    }
    getBusinessRoles() {
        return this.httpClient.get(UrlAPI + 'data/getbusinessroles', httpOption(this.userInfo.access_token, this.languageId));
    }

    getClassifyGroupOCOPs() {
        return this.httpClient.get(UrlAPI + 'data/find/classifygroups', httpOption(this.userInfo.access_token, this.languageId));
    }


    sendVerifyCode(entity, verifyType, token) {
        return this.httpClient.post(UrlAPI + 'account/verifyotp', {
            'entity': entity,
            'verify_type': verifyType
        }, httpOption('', this.languageId, token))
    }

    getCertifications() {
        return this.httpClient.get(UrlAPI + 'data/standar/certifications', httpOption('', this.languageId));
    }

    getMenus() {
        let roleId = Number(localStorage.getItem('role_id'));
        
        if (roleId == null || roleId == undefined) {
            roleId = 1;
        }
        if (roleId == 1) {
            return this.httpClient.get('/assets/data/menu.json')
        } else if (roleId == 2) {
            return this.httpClient.get('/assets/data/menu_sx.json')
        } else if (roleId == 3) {
            return this.httpClient.get('/assets/data/menu_ecommerce.json');
        } else {
            return this.httpClient.get('/assets/data/menu.json')
        }
    }
}

