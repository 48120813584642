<div class="page-container">
  <div class="page-header">
    <nav class="navbar navbar-expand-lg d-flex justify-content-between">
      <div class="" id="navbarNav">
        <ul class="navbar-nav" id="leftNav">
          <li class="nav-item">
            <a class="nav-link" id="sidebar-toggle" (click)="toggleSidebarClick()"
               href="javascript:void(0)"><i class="fas fa-bars"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link fw-bolder text-info" style="font-size: 16px;">{{title}}</a>
          </li>
        </ul>
      </div>
      <div class="logo">
        <a class="navbar-brand" href="/"></a>
      </div>
      <div class="" id="headerNav">
        <ul class="navbar-nav">

          <li class="nav-item dropdown">
            <a class="nav-link profile-dropdown" href="#" id="profileDropDown" role="button"
               data-bs-toggle="dropdown" aria-expanded="false"><img
              src="/assets/images/avatars/config_icon.png" alt=""></a>
            <div class="dropdown-menu dropdown-menu-end profile-drop-menu"
                 aria-labelledby="profileDropDown">
              <!--
              <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal"
                  data-bs-target="#menumodal">Cấu hình</a>
                  -->
              <a class="dropdown-item" routerLink="/user/role">Chọn chức năng</a>
              <a class="dropdown-item" href="javascript:void(0)"><i data-feather="user"></i>Tài khoản</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i
                data-feather="log-out"></i>{{'layout.topbar.signout' | translate}}</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <div class="page-sidebar" *ngIf="menuItems">
    <app-slider-menu [items]="menuItems"></app-slider-menu>
  </div>
  <div class="page-content">
    <div class="main-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


<div class="modal" id="menumodal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cấu hình chức năng</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="tree mb-5" *ngIf="menuItems">
          <ul>
            <li *ngFor="let item of menuItems;index as i"><a href="javascript:void(0)">{{i + 1}}.
              {{item.title}} <input name="menu_parent{{i}}" type="checkbox" (change)="checkChange()"
                                    [(ngModel)]="item.is_show"/></a>

              <ul *ngIf="item.is_dropdown">
                <li *ngFor="let child of item.items;index as childidx">{{i + 1}}.{{childidx + 1}}
                  {{child.title}} <input name="menu_child{{i}}.{{childidx}}" (change)="checkChange()"
                                         type="checkbox" [(ngModel)]="child.is_show"/></li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="sharemodal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">CHIA SẼ DỮ LIỆU DOANH NGHIỆP</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="tree mb-5">
          <ul>
            <li><a href="#">1. HỒ SƠ NĂNG LỰC DOANH NGHIỆP</a>

              <ul>
                <li>1.1 Thông tin pháp lý <input type="checkbox" checked/></li>
                <li>1.2 Thông tin nhân sự <input type="checkbox" checked/></li>
                <li>1.3 Chứng chỉ tiêu chuẩn <input type="checkbox" checked/></li>
              </ul>
            </li>
            <li><a href="#">2. HỒ SƠ NĂNG LỰC VÙNG NGUYÊN LIỆU <input type="checkbox" checked/></a>
            </li>
            <li><a href="#">3. HỒ SƠ NĂNG LỰC SẢN XUẤT VÀ CHẾ BIẾN <input type="checkbox" checked/></a>
            </li>
            <li><a href="#">4. HỒ SƠ NĂNG LỰC SẢN PHẨM (SKU) <input type="checkbox" checked/></a>
            </li>
            <li><a href="#">5. HỒ SƠ NĂNG LỰC ÁP DỤNG KHOA HỌC CÔNG NGHỆ <input type="checkbox"
                                                                                checked/></a>
            </li>
            <li><a href="#">6. HỒ SƠ TRUYÊN THÔNG QUẢNG CÁO <input type="checkbox" checked/></a>
            </li>
          </ul>
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Tải lên file tài liệu</label>
          <input class="form-control" type="file" id="formFile">
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Tải lên file hình ảnh</label>
          <input class="form-control" type="file" id="formFile">
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Tải lên file video clip</label>
          <input class="form-control" type="file" id="formFile">
        </div>
        <div class="mb-3">
          <label for="formFile" class="form-label">Tải lên file âm thành</label>
          <input class="form-control" type="file" id="formFile">
        </div>
        <div class="d-grid gap-2 mb-5">
          <button class="btn btn-primary" type="button"><i class="fas fa-share-square"></i> TẠO ĐƯỜNG DẪN CHIA
            SẼ (URL)
          </button>
          <button class="btn btn-success" type="button"><i class="fas fa-qrcode"></i> TẠO MÃ QR CHIA SẼ
            (HÌNH)
          </button>
          <button class="btn btn-warning" type="button"><i class="fas fa-file-pdf"></i> TẠO TÀI LIỆU CHIA SẼ
            (PDF)
          </button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="shareocophatinhmodal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">CHIA SẼ HỒ SƠ DOANH NGHIỆP LÊN OCOP HÀ TĨNH</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="checkPublicInfo">
        <h4 *ngIf="checkPublicInfo.is_public == 1"
            style="text-transform: uppercase;font-weight: bold;color: #fbea03; text-align: center;"
            class="mb-5 mt-3">Hồ Sơ Pháp Lý của doanh nghiệp bạn đã được chia sẽ</h4>
        <h4 *ngIf="checkPublicInfo.is_public == 0"
            style="text-transform: uppercase;font-weight: bold;color: #f15321; text-align: center;"
            class="mb-5 mt-3">Hồ Sơ Pháp Lý của doanh nghiệp bạn chưa được chia sẽ</h4>
        <div class="d-grid gap-2 mb-5">
          <a *ngIf="checkPublicInfo.is_public == 1" class="btn btn-primary"
             href="http://ocophatinh.com/thong-tin-chu-the/{{checkPublicInfo.slug_id}}/{{checkPublicInfo.company_name | changeAliasPipe}}"
             target="_blank"><i class="fas fa-share-square"></i> XEM TRANG THÔNG TIN</a>


          <button (click)="changePublicOCOPHatinh(0)" class="btn btn-danger mt-3 mb-3"
                  *ngIf="checkPublicInfo.is_public == 1" type="button"><i
            class="fas fa-qrcode"></i> TÔI KHÔNG MUỐN
            CHIA SẼ THÔNG TIN ?
          </button>

          <button (click)="changePublicOCOPHatinh(1)" class="btn btn-success mt-3 mb-3"
                  *ngIf="checkPublicInfo.is_public == 0" type="button"><i
            class="fas fa-qrcode"></i> TÔI MUỐN
            CHIA SẼ THÔNG TIN ?
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
