import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DateFormatPipe } from '../settings/dateformatpipe';
import { DateNumberFormatPipe } from '../settings/datenumberformatpipe';
import { DateTimeFormatPipe } from '../settings/datetimeformat';
import { DateTimeNumberFormatPipe } from '../settings/datetimenumberformat';
import { SelectPickerComponent } from './select-picker/select-picker.component';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MagnificPopupComponent } from './magnific-popup/magnific-popup.component';
import { Select2Component } from './select2/select2.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InputTextMaskComponent } from "./input-text-mask/input-text-mask.component";
import { FullPageThemeDirective } from "../directives/full-page-theme.directive";
import { TagsinputDirective } from "../directives/tagsinput.derective";
import { ApexChartComponent } from "./apex-chart/apex-chart.component";
import { MagnificShowImageDirective } from "../_directives/magnific-popup-image.directive";
import { FirebaseGetPathtPipe } from "../pipes/firebase-getpath.pipe";
import { MyModalComponent } from "./my-modal/my-modal.component";
import { ChangeAliasPipe } from "../pipes/change-alias.pipe";


export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        SelectPickerComponent,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateNumberFormatPipe,
        DateTimeNumberFormatPipe,
        DatePickerComponent,
        MagnificPopupComponent,
        Select2Component,
        InputTextMaskComponent,
        FullPageThemeDirective,
        TagsinputDirective,
        ApexChartComponent,
        MagnificShowImageDirective,
        FirebaseGetPathtPipe,
        MyModalComponent,
        ChangeAliasPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        SelectPickerComponent,
        DateFormatPipe,
        DateTimeFormatPipe,
        DateNumberFormatPipe,
        DateTimeNumberFormatPipe,
        DatePickerComponent,
        MagnificPopupComponent,
        Select2Component,
        InputTextMaskComponent,
        FullPageThemeDirective,
        TagsinputDirective,
        ApexChartComponent,
        MagnificShowImageDirective,
        FirebaseGetPathtPipe,
        MyModalComponent,
        ChangeAliasPipe
    ]
})
export class SharedModule { }