<div class="modal fade" id="{{modalId}}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div [ngClass]="modalClass">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-3 pb-3">
                <ng-content></ng-content>
            </div>
            <div class="modal-footer" *ngIf="isShowFooter">
                <button *ngIf="isShowCloseFooter" type="button" data-bs-dismiss="modal" class="btn btn-danger btn-simple">Đóng</button>
                <button *ngIf="isShowAcceptFooter" type="button" class="btn btn-simple btn-primary"
                    (click)="submitAccept()">{{btnAcceptText}}</button>
            </div>
        </div>
    </div>
</div>
<!--  End Modal -->

